
import Vue from 'vue'

import store from './store'
store.commit('setRecaptchaSitekey', window.env.recaptcha_sitekey || false);

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
    siteKey: window.env.recaptcha_sitekey,
    loaderOptions: {
        autoHideBadge: true
    }
})

import Spinner from '@/components/Spinner.vue'
Vue.component('Spinner', Spinner)

import VueScreen from 'vue-screen'
Vue.use(VueScreen, 'tailwind')

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {locale: 'en'})

import InfiniteLoading from 'vue-infinite-loading'
Vue.use(InfiniteLoading, { slots: { noMore: '' } })

import '@/assets/css/animation.scss'

import '@/assets/css/tailwind.scss'

// NB: DISABLED FASTCLICK AS IT WAS CAUSING A BUG ON IPHONE WITH THE FactorDropdown
// import FastClick from 'fastclick'
// FastClick.attach(document.body)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import scrollToTop from './scrollToTop';
window.scrollToTop = window.scrollToTop || scrollToTop;

window.env = window.env || {};

import qs from './qs';
window.qs = window.qs || new qs();

// Adjust the html font-size to the value of querystring "fs"
// (the general app font size will scale accordingly)
// eg: ?fs=10 (font-size: 10px)
// eg: ?fs=12 (font-size: 12px)
// eg: ?fs=14 (font-size: 14px) <- default
let fs = window.qs.has('fs') ? parseInt(window.qs.get('fs'), 10) : 0;

if (fs) { document.querySelector('html').style.fontSize = fs + 'px'; }
