
import 'es6-promise/auto'

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)


let env = window.env || {};

let auth = localStorage.getItem('auth') || false;

    auth = auth ? JSON.parse(auth) : {};

let jwt = auth.jwt || env.jwt || '';

let baseURL = env.clevva_api_endpoint || '';


Vue.axios.defaults.baseURL = baseURL;

Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;

Vue.axios.defaults.headers.post['Content-Type'] = 'application/json';


// Add a response interceptor
Vue.axios.interceptors.response.use(function (response) {

    // Return the response data
    return response;

  }, function (err) {

    let res   = err.response || {};
    let data  = res.data     || {};
    let error = data.error   || {};
    let code  = error.code   || 0;
    let message = error.message || '';

    if (code === 412) {

        // Expired token
        if (message === 'Expired token') {

            // Remove expired auth
            localStorage.removeItem('auth');

            alert('Your login has expired, please login again.');

            // Reload the page
            location.reload();
        }
    }

    // Return the response error
    return Promise.reject(error);

  });


const api = {

    get (route, params) { return Vue.axios.get(route, params); },

    post (route, params) { return Vue.axios.post(route, params); },

    put (route, params) { return Vue.axios.put(route, params); },

    patch (route, params) { return Vue.axios.patch(route, params); },

    delete (route, params) { return Vue.axios.delete(route, params); }

};


export { api };
