export default function scrollToTop(scrollDuration, topOffset) {

  topOffset = parseInt(topOffset || 0, 10);

  var scrollStep = -window.scrollY / (scrollDuration / 15);
  var scrollIntr = setInterval(function() {

    if ( window.scrollY > topOffset ) {
      window.scrollBy( topOffset, scrollStep );
    } else {
      clearInterval(scrollIntr);
    }

  }, 15);

}
