import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from './store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/state/:guid',
      name: 'state',
      component: () => import(/* webpackChunkName: 'VaState' */ '@/components/VaState.vue')
    },
    {
      path: '/chat/:guid',
      name: 'chat',
      component: () => import(/* webpackChunkName: 'VaChat' */ '@/components/VaChat.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: 'Login' */ '@/views/Login.vue')
    },
    {
      path: '/va/:code',
      name: 'va',
      beforeEnter: (to, from, next) => {

        let code = to.params.code || false;

        if ( !code ) { next({ path: '/notfound', replace: true }); return; }

        store.dispatch('startVa', { code }).then( resp => {

          if ( resp.error ) { next({ path: '/notfound', replace: true }); return; }

        });
      }
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: 'NotFound' */ '@/views/NotFound.vue')
    }
  ]
})

/* Scroll to top of page for every route */
router.beforeEach((to, from, next) => {

  let routeName = to['name'] || '';

  store.commit('setRouteName', routeName);

  // window.scrollTo({ top: 0, behavior: 'smooth' });

  next();
})

export default router;
