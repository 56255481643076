import 'es6-promise/auto'

import { api } from '@/api.js'

const sdk = {

    start (va, session) {

        va = va || {};
        session = session || {};

        let payload = {
            va: {
                code: va.code || ''
            },
            session: {
                code: session.code || '',
                factors: session.factors || []
            }
        };

        return api.post('/va/start', payload)

            .then((resp) => {

                return resp.data || { data: {} };

            })

            .catch((error) => {

                if (error.response) {

                    return { error: error.response.data.error };

                } else {

                    return { error: { message: 'Something went wrong!' } };
                }
            });

    },

    load (stateid) {

        stateid = stateid || 0;

        return api.get(`/va/load/${stateid}`)

            .then((resp) => {

                return resp.data || { data: {} };

            })

            .catch((error) => {

                if (error.response) {

                    return { error: error.response.data.error };

                } else {

                    return { error: { message: 'Something went wrong!' } };
                }
            });
    },

    action (action) {

        action = action || {};

        return api.post('/va/action', { action })

            .then((resp) => {

                return resp.data || { data: {} };

            })

            .catch((error) => {

                if (error.response) {

                    return { error: error.response.data.error };

                } else {

                    return { error: { message: 'Something went wrong!' } };
                }
            });
    },

    session (stateid) {

        stateid = stateid || 0;

        return api.get(`/va/session/${stateid}`)

            .then((resp) => {

                return resp.data || { data: {} };

            })

            .catch((error) => {

                if (error.response) {

                    return { error: error.response.data.error };

                } else {

                    return { error: { message: 'Something went wrong!' } };
                }
            });
    },


    login (login) {

        return api.post('/login', login)

            .then((resp) => {

                return resp.data || { data: {} };

            })

            .catch((error) => {

                if (error.message) {
                    return { error: error };
                } else {
                    return { error: { message: 'Something went wrong!' } };
                }

            });
    }

};

export { sdk };
