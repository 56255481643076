<template>
<div>

  <div :class="{ 'bg-white opacity-0 animated fadeInToFifty fixed inset-0 z-50 flex items-center justyify-center' : mask }"></div>

  <div class="spinner-box animated slower fadeIn" :class="{ 'fixed inset-0 z-50 flex items-center justyify-center' : mask }">

    <div class="spinner"></div>

    <div v-if="text" class="spinner-text">{{ text }}</div>

  </div>

</div>
</template>

<script>
export default {

  name: 'Spinner',

  props: {
    mask: { type: Boolean, default: false },
    text: { type: String, default: '' }
  }

}
</script>
