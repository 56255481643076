<template>

    <div class="flex flex-col items-stretch min-h-screen antialiased">

        <div v-if="auth" style="padding-bottom:63px">
            <div xstyle="z-index:2000" class="fixed z-40 flex items-center w-full p-4 bg-telkom-blue text-white shadow">
                <div class="flex-auto">{{ auth.firstname }} {{ auth.lastname }} ({{ auth.email }})</div>
                <button @click="logout" class="btn-telkom-blue-dark hover:bg-telkom-blue-dark">Log Out</button>
            </div>
        </div>

        <header v-if="showHeader" class="bg-telkom-blue px-6 py-4">
            <div class="flex items-center">
                <a href="http://www.telkom.co.za/today/"><img src="telkom/img/telkom-logo.svg" alt="Telkom logo" style="max-width:101px;max-height:25px;"></a>
                <h1 class="font-telkom-regular text-white text-2xl ml-1 whitespace-no-wrap"><span class="px-4">|</span>Get Help</h1>
                <nav class="ml-auto"></nav>
            </div>
        </header>

        <div
             class="relative flex-auto"
            :class="`${ $store.state.routeName === 'home' ? 'bg-telkom-blue' : 'bg-gray-100' }`"
        >
            <Spinner v-if="stateLoading" :mask="true" />
            <router-view style="margin-bottom:115px;" />
        </div>

        <footer v-if="showFooter" class="bg-telkom-blue p-6 absolute bottom-0 w-full">
            <div class="text-white text-right" style="font-size:9.3px;line-height:1.6em;">
                <div><a class="inline-block mb-px" href="http://www.telkom.co.za/today/"><img src="telkom/img/telkom-logo.svg" alt="Telkom logo" style="max-width:78px"></a></div>
                <div class="pt-1">
                    <a class="footer-link" href="http://www.telkom.co.za/today/">Home</a> |
                    <a class="footer-link" href="http://www.telkom.co.za/paia">PAIA</a> |
                    <a class="footer-link" href="http://www.telkom.co.za/terms">Terms and Conditions</a> |
                    <a class="footer-link" href="http://www.telkom.co.za/about_us/download/POPI_Privacy_Statement_6_June_2018.pdf">POPI</a> |
                    <a class="footer-link" href="http://www.telkom.co.za/today/sitemap/">Sitemap</a>
                </div>
                <div class="pt-1">&copy; Telkom SA SOC Limited. 2019 All Rights Reserved.<br>An authorised Financial Services Provider – FSP no. 46037</div>
            </div>
        </footer>

        <portal-target name="modal"></portal-target>

    </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {

    name: 'App',

    computed: {

        ...mapState(['stateLoading', 'routeName']),

        auth() {
            var auth = localStorage.getItem('auth') || false;
            return auth ? JSON.parse(auth) : false;
        },

        showHeader() {
            // hh = hide header
            return !window.qs.has('hh');
        },

        showFooter() {
            // hf = hide footer
            return !window.qs.has('hf');
        }

    },

    // data() {

    //     return {

    //         faq_va_code: window.env.faq_va_code || ''

    //     }

    // },

    methods: {

        ...mapActions(['startVa', 'scrollToTop']),

        goHome() {
            this.routeName === 'home' ? this.scrollToTop() : this.$router.push({ name: 'home' });
        },

        logout() {
            localStorage.removeItem('auth');
            location.reload();
        }

    }

}
</script>
