import 'es6-promise/auto'

import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import localStore from './localStore'

import { sdk } from '@/sdk.js'

Vue.use(Vuex)

const store = new Vuex.Store({

  state: {

    error: {},

    recaptchaSitekey: false,

    showHeroModal: false,

    startBusy: false,

    actionBusy: false,

    loadBusy: false,

    stateLoading: false,

    stateid: 0,

    vastate: '',

    routeName: '',

    data: {}

  },

  mutations: {

    setError (state, error) { state.error = error || {}; },

    setRecaptchaSitekey (state, sitekey) { state.recaptchaSitekey = sitekey || false; },

    setShowHeroModal (state, show) { state.showHeroModal = show || false; },

    setStartBusy (state, busy) { state.startBusy = busy || false; },

    setActionBusy (state, busy) { state.actionBusy = busy || false; },

    setLoadBusy (state, busy) { state.loadBusy = busy || false; },

    setStateLoading (state, loading) { state.stateLoading = loading || false; },

    setStateid (state, stateid) { state.stateid = stateid || 0; },

    setVastate (state, vastate) { state.vastate = vastate || ''; },

    setRouteName (state, routeName) { state.routeName = routeName || ''; },

    setData (state, data) { state.data = data || {}; }

  },

  actions: {

    scrollToTop(context, delay) {

        setTimeout(() => { window.scrollToTop(delay || 500); }, 100);
    },

    startVa ({ commit, state }, va) {

      if ( state.startBusy ) { return; }

      if ( !va ) {

        let error = state.error || { message: 'No va has been defined...' };

        commit('setError', error);

        return { error };
      }

      commit('setStartBusy', true);
      commit('setStateLoading', true);

      return sdk.start(va)

        .then((resp) => {

          let data  = resp.data  || {};
          let error = resp.error || {};

          if (error.message) {

            error = error.message ? error : { message: 'An error occurred' };

            commit('setStartBusy', false);
            commit('setStateLoading', false);
            commit('setError', error);

            return { error };
          }

          if (data.stateid) {

            localStore.clearAll();

            commit('setStateid', data.stateid);

            let guid = localStore.setStateid(data.stateid) || '';

            // router.push({ path: `/state/${guid}` });
            router.push({ path: `/chat/${guid}` });

            commit('setStartBusy', false);

            return { data };
          }
        });

    },

    // applyAction ({ state, dispatch }, action) {

      // let form = state.data.form || {};

      // let formfields = form.fields || [];

      // action.formfields = formfields;

      // if ( state.recaptchaActive ) {

      //   dispatch('validateRecaptcha', action).then( valid => {

      //     if (valid) {
      //       return dispatch('flowAction', action);
      //     }

      //   });

      // } else {

      //   return dispatch('flowAction', action);

      // }

    // },

    // applyActionForm ({ dispatch }, payload) {

    //   let data   = payload.data;
    //   let action = payload.action;

    //   return dispatch('applyAction', action);

    // },

    // applyAction ({ dispatch }, action) {
    applyAction ({ dispatch }, payload) {

      // return dispatch('flowAction', action);
      return dispatch('flowAction', payload);

    },

    // flowAction ({ commit, state }, action) {
    flowAction ({ commit, state }, payload) {

      if ( state.actionBusy ) { return; }

      commit('setError', {});
      commit('setActionBusy', true);
      commit('setStateLoading', true);

      let action   = payload.action || false;
      let cardData = payload.data || false;

      if ( (typeof action === "string") && !cardData) {
        action = payload;
        cardData = state.data;
      }

      return sdk.action(action)

        // .then(function(resp) {
        .then( (resp) => {

          let data    = resp.data    || {};
          let error   = resp.error   || data.error || false;
          let stateid = data.stateid || 0;

          if (error) {

            error = error.message ? error : { message: 'An error occurred' };

            commit('setActionBusy', false);
            commit('setStateLoading', false);
            commit('setError', error);

            // Update the state.data.form (if returned by the 'action')
            if (data.form) {

              // let dataUpdated = state.data;
                  cardData.form.fields = data.form.fields;

              let recaptcha = data.form.recaptcha || {};
              if (recaptcha.error) {
                cardData.form.recaptcha.error = recaptcha.error;
              }

              // commit('setData', dataUpdated);
            }

            return { error };
          }

          if (stateid) {

            let guid = localStore.setStateid(stateid);

            // router.push({ path: `/state/${guid}` });
            router.push({ path: `/chat/${guid}` });

            commit('setStateid', stateid);

          } else {

            commit('setStateid', stateid);
            commit('setData', data);
          }

          commit('setActionBusy', false);

          return { data };
        });

    },

    loadState ({ commit, state }, stateid) {

      if ( state.loadBusy ) { return; }

      commit('setError', {});
      commit('setLoadBusy', true);
      commit('setStateLoading', true);

      return sdk.load(stateid)

        .then(function(resp) {

          let data  = resp.data  || {};
          let error = resp.error || data.error || false;

          if (error) {

            error = error.message ? error : { message: 'An error occurred' };

            commit('setError', error);
            commit('setLoadBusy', false);
            commit('setStateLoading', false);

            return { error };
          }

          if (data) {

            commit('setStateid', stateid);
            commit('setVastate', data.state || '');
            commit('setData', data);
          }

          commit('setLoadBusy', false);
          commit('setStateLoading', false);

          return { data };
        });

    },

    componentAction (o, action) {

      return sdk.action(action)

        .then(function(resp) {

          let data  = resp.data  || {};
          let error = resp.error || data.error || false;

          if (error) {

            error = error.message ? error : { message: 'An error occurred' };

            return { error };
          }

          return { data };
        });

    }

  }

});

export default store;
