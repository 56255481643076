// Easy access to the querystring params
export default function() {

    // Using the npm url-parse library
    const urlparse = require('url-parse');

    let x = {
        parts: urlparse(location, true)
    };

    // All query params
    let o = {
        query: x.parts.query
    };

    // Does the key exist
    o.has = function(key) {
        return o.query[key] !== undefined;
    };

    // Get the key value
    o.get = function(key) {
        return o.query[key] || '';
    };

    return o;
}
