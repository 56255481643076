
const nid = require('nid')

const localStore = require('store')

localStore.getJourney = function() {

    return this.get('journey') || {};
};

localStore.setJourney = function(journey) {

    this.set('journey', journey || {});
};

localStore.makeGuid = function(len) {

    let hex = nid({ hex: 1, length: len || 5 });

    return hex();
};

localStore.setStateid = function(stateid) {

    let journey = this.getJourney();

    let guid = this.makeGuid();

    journey[guid] = stateid.toString(); // <- SUPER IMPORTANT TO USE ".toString()"

    this.setJourney(journey);

    return guid;
};

localStore.getStateid = function(guid) {

    let journey = this.getJourney();

    return journey[guid] || 0;
};

localStore.clearAll = function() {

    this.setJourney({});
};

export default localStore;
